import settingServices from '@/modules/Company/settings/services/settings'

import driverSettingServices from '@/modules/Driver/settings/services/settings'

import authMixins from './auth-permission'

export default {
  mixins: [authMixins],
  data () {
    return {
      vendorInfo: {},
      storeActive: {},
      myStores: []
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    getInfo () {
      if (this.hasPer('transportation.driver')) {
        driverSettingServices.getMerchantInfo().then(res => {
          this.storeActive.status = res.data.status
          if (this.storeActive.status !== 'success') {
            if (this.storeActive.status === 'pending') {
              this.$router.push({ name: 'driverSettings' })
              this.$store.commit('changeStoreStatus', 'pending')
              localStorage.setItem('storeStatus', 'pending')
            } else if (this.storeActive.status === 'processing') {
              this.$router.push({ name: 'driverProcessing' })
              this.$store.commit('changeStoreStatus', 'processing')
              localStorage.setItem('storeStatus', 'processing')
            }
          } else {
            localStorage.setItem('storeStatus', 'completed')
            this.$store.commit('changeStoreStatus', 'completed')
          }
        })
      } else if (this.hasPer('transportation.company_owner')) {
        settingServices.getMerchantInfo().then(res => {
          this.myStores = res.data.stores
          const selectedStoreIndex = res.data.stores.findIndex(store => {
            return store.id === res.data.default_store_id
          })
          this.storeActive = res.data.stores[selectedStoreIndex]
          if (this.storeActive.status !== 'completed') {
            if (this.storeActive.status === 'pending') {
              this.$router.push({ name: 'setting' })
              this.$store.commit('changeStoreStatus', 'pending')
              localStorage.setItem('storeStatus', 'pending')
            } else if (this.storeActive.status === 'processing') {
              this.$router.push({ name: 'processing' })
              this.$store.commit('changeStoreStatus', 'processing')
              localStorage.setItem('storeStatus', 'processing')
              localStorage.setItem('vendorActiveStore', JSON.stringify(res.data.stores[selectedStoreIndex]))
            }
          } else {
            localStorage.setItem('vendorActiveStore', JSON.stringify(res.data.stores[selectedStoreIndex]))
            localStorage.setItem('storeStatus', 'completed')
            this.$store.commit('changeStoreStatus', 'completed')
          }
        })
      }
    },
    changeDefaultStore (storeId) {
      settingServices.changeDefaultStore({ default_store_id: storeId }).then(() => {
        this.$router.push({ name: 'index' })
        this.getInfo()
      })
    }
  }
}
// userRole (type) {
//   if (type === 'doctor' && this.hasPer('transportation.driver')) {
//     driverSettingServices.getMerchantInfo.then((res) => {
//       this.clientInfo = res.data
//       if (res.data.status !== 'accepted') {
//         switch (res.data.status) {
//           case 'pending': {
//             this.$router.push({ name: 'driverSettings' })
//             break
//           }
//           case 'processing': {
//             this.$router.push({ name: 'driverProcessing' })
//             // this.$router.push({ name: 'doctorSettings' })
//             break
//           }
//           case 'rejected': {
//             this.$router.push({ name: 'driverProcessing' })
//             // this.$router.push({ name: 'doctorSettings' })
//             break
//           }
//           case 'suspended': {
//             this.$router.push({ name: 'driverProcessing' })
//             // this.$router.push({ name: 'doctorSettings' })
//             break
//           }
//           default:
//             this.$router.push({ name: 'driverDashboard' })
//             break
//         }
//       }
//     })
//   } else if (
//     type === 'clinic_owner' &&
//     this.hasPer('veterinary.clinic_owner')
//   ) {
//     settingServices.changeDefaultStore().then((res) => {
//       this.clientInfo = res.data
//       if (res.data.status !== 'accepted') {
//         switch (res.data.status) {
//           case 'pending': {
//             this.$router.push({ name: 'hospitalSetting' })
//             break
//           }
//           case 'processing': {
//             this.$router.push({ name: 'hospitalProcessing' })
//             break
//           }
//           case 'rejected': {
//             this.$router.push({ name: 'hospitalProcessing' })
//             break
//           }
//           case 'suspended': {
//             this.$router.push({ name: 'hospitalProcessing' })
//             break
//           }
//           default:
//             // localStorage.setItem('clientStatus', 'accepted')
//             this.$router.push({ name: 'hospitalDashboard' })
//             break
//         }
//       }
//     })
//   }
// }
